import {versionInfo} from './version/info';

export const config = {
  mobil: {theme: null},
  previewMode: true,
  // fallback for modules using old config key
  assetServer: '{{assets#cdn}}',
  assets: {
    cdn: '{{apis#baseUri}}',
    api: null
  },
  layout: {
    redirect: {
      hidePath: true
    }
  },
  translation: {
    locales: {
      download: {
        uri: '/assets/locales/%locale%.yaml?ts=%ts%'
      }
    }
  },
  apis: {
    login: {
      heartbeat: {
        uri: '{{apis#baseUri}}/api/module/login/heartbeat'
      },
      uri: '{{apis#baseUri}}/api/module/login/auth'
    },
    ums: {
      uri: '{{apis#baseUri}}/api/module/ums',
      resources: {
        user: '/user',
        tenant: '/tenant',
        role: '/role',
        authTokenValidate: '/auth/token/validate'
      }
    },
    license: {
      manager: {
        uri: '{{apis#baseUri}}/api/module/license'
      },
      info: {
        uri: '{{apis#baseUri}}/api/module/license/info'
      }
    },
    client: {
      uri: '{{apis#baseUri}}/api/module/client'
    },
    instance: {
      uri: '{{apis#baseUri}}/api/module/instance',
    },
    storage: {
      uri: '{{apis#baseUri}}/api/module/storage'
    },
    cluster: {
      uri: '{{apis#baseUri}}/api/module/cluster'
    },
  },
  policy: {
    password: {
      regex: ['/.{8,}/', '/[A-Z]{1}/', '/[^A-Za-z0-9]{1}/', '/^[^äöüß]*$/i'],
      hint: 'Das Passwort muss mind. 8 Zeichen lang sein und aus mind. 1 Großbuchstaben '
        + 'und 1 Sonderzeichen bestehen. Folgende Zeichen sind nicht erlaubt: äöüß'
    }
  },
  versions: {
    base64: versionInfo
  },
  license: {
    generator: {
      pubkey: {
        mode: false
      }
    }
  }
};


