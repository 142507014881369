/**
 * Modifier for config. Converts "env to config" friendly domain
 * config format to actual domain config format.
 *
 * @param config
 */
export function modifier(config) {
  if (typeof config.domain === 'object') {
    (typeof config.domains !== 'object') && (config.domains = {});
    for (const key of Object.keys(config.domain)) {
      const domainCfg = config.domain[key];
      (typeof domainCfg.domain === 'string') && (config.domains[domainCfg.domain] = domainCfg);
    }
  }
}
