import {Component} from '@angular/core';
import {LayoutService} from '@ngmedax/layout';
import {VERSION} from '../version';

@Component({
  selector: 'app-root',
  template: '<app-layout></app-layout>',
  styles: []
})
export class AppComponent {
  constructor(private layoutService: LayoutService) {
    layoutService.setVersion(VERSION);
  }
}
